<template>
  <div class="content-layout">
    <v-dialog v-model="showModal" persistent width="600px">
      <v-card>
        <v-card-title>
          <span class="lfont ml-2" style="font-family:Noto Sans Lao;">{{
            $t("empLeave.ApproveTable.to")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="label-input">{{
                  $t("empLeave.ApproveTable.hrApprove")
                }}</label>
                <v-select
                  :items="listHr"
                  outlined
                  dense
                  item-value="user_id"
                  item-text="name"
                  v-model="approved_by_hr"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveChange">
            Save
          </v-btn>
          <v-btn color="dark" text @click="closeModal">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { emit } from "process";

export default {
  props: ["id", "dialog"],
  data() {
    return {
      listHr: [],
      approved_by_hr: "",
      remark: "",
      server_errors: {
        remark: "",
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    fetchHr() {
      this.isLoading = true;

      this.$axios
        .get(`only/hr`)
        .then((res) => {
          if (res.status === 200) {
            console.log("modal", res);
            this.isLoading = false;
            this.listHr = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    saveChange() {
      const item = {
        approved_by_hr: this.approved_by_hr,
      };
      this.$axios
        .post(`/line/manager/approve/employee/leave/${this.id}`, item)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("close");
            this.$emit("success");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
  },
  computed: {
    showModal: function() {
      return this.dialog;
    },
  },
  created() {
    this.fetchHr();
  },
};
</script>

<style scoped lang="scss">
.content-layout {
  padding: 20px;
  font-family: "Noto Sans Lao" !important;
}
.btn {
  padding: 30px !important;
}
.btn-save {
  color: white !important;
  margin-right: 30px;
}
.user {
  color: blue;
}
</style>

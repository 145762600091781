<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4" lg="4">
        <label class="label-input">{{ $t("e-approve.label_select") }}</label>
        <v-select
          :items="todoStatus"
          outlined
          dense
          item-value="value"
          item-text="name"
          v-model="status"
          @change="searchFilterItem()"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="section-form-content">
          <div class="block-content">
            <table class="table table-view" v-if="listEmployeeLeave.length > 0">
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("empLeave.ApproveTable.leaveFrom") }}
                  </th>
                  <th class="text-left">
                    {{ $t("empLeave.ApproveTable.idCard") }}
                  </th>
                  <th class="text-left">
                    {{ $t("empLeave.ApproveTable.branch") }}
                  </th>
                  <th class="text-left">
                    {{ $t("empLeave.ApproveTable.dept") }}
                  </th>
                  <th class="text-left">
                    {{ $t("empLeave.ApproveTable.leaveType") }}
                  </th>
                  <th class="text-left">
                    {{ $t("empLeave.ApproveTable.leaveTaken") }}
                  </th>
                  <th class="text-left">
                    {{ $t("empLeave.ApproveTable.leaveAvailable") }}
                  </th>
                  <th class="text-left">
                    {{ $t("empLeave.ApproveTable.backToWork") }}
                  </th>
                  <th class="text-left">
                    {{ $t("empLeave.ApproveTable.leaveDetail") }}
                  </th>
                  <!-- <th class="text-left">
                      {{ $t("empLeave.ApproveTable.hrApprove") }}
                    </th> -->
                  <th class="text-left">
                    {{ $t("empLeave.ApproveTable.status") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in listEmployeeLeave" :key="index">
                  <td>{{ item.employee.name }}</td>
                  <td>{{ item.employee.card_id }}</td>
                  <td>{{ item.employee.branch }}</td>
                  <td>{{ item.employee.department }}</td>
                  <td>{{ item.holiday.name }}</td>

                  <td>
                    <span
                      v-for="(leave, indexl) in item.leave_time"
                      :key="indexl"
                    >
                      <span style="font-size: 70%;"
                        >{{ leave.date
                        }}<span v-if="leave.type == 'hour'"
                          >({{ leave.start_time }} - {{ leave.end_time }})</span
                        ></span
                      ><br />
                    </span>
                    <span v-if="item.summary_leave.day > 0"
                      >{{ item.summary_leave.day }} ມື້
                    </span>
                    <span v-if="item.summary_leave.hour > 0"
                      >{{ item.summary_leave.hour }} ຊົ່ວໂມງ
                    </span>
                    <span v-if="item.summary_leave.minute > 0"
                      >{{ item.summary_leave.minute }} ນາທີ
                    </span>
                  </td>
                  <td>
                    <span v-if="item.holiday.remaining_holiday.days > 0"
                      >{{ item.holiday.remaining_holiday.days }} ມື້
                    </span>
                    <span v-if="item.holiday.remaining_holiday.hours > 0"
                      >{{ item.holiday.remaining_holiday.hours }} ຊົ່ວໂມງ
                    </span>
                    <span v-if="item.holiday.remaining_holiday.minute > 0"
                      >{{ item.holiday.remaining_holiday.minute }} ນາທີ
                    </span>
                  </td>

                  <td>{{ item.come_back_date }}</td>
                  <td>{{ item.remark }}</td>

                  <td>
                    <span v-if="item.status == 'pending'">
                      <v-chip
                        small
                        style="background-color: rgb(102, 104, 102) !important;color:white !important"
                      >
                        {{ $t("empLeave.status.wait_for_approved") }}
                      </v-chip>
                    </span>
                    <span v-if="item.status == 'approved'">
                      <v-chip
                        small
                        style="background-color: rgb(51, 73, 98) !important;color:white !important"
                      >
                        {{ $t("empLeave.status.lineManagerApproved") }}
                      </v-chip>
                    </span>
                    <span v-if="item.status == 'success'">
                      <v-chip
                        small
                        style="background-color: rgb(37, 184, 37) !important;color:white !important"
                      >
                        {{ $t("empLeave.status.approved") }}
                      </v-chip>
                    </span>
                    <span v-if="item.status == 'rejected'">
                      <v-chip
                        small
                        style="background-color: red !important;color:white !important"
                      >
                        {{ $t("empLeave.status.rejected") }}
                      </v-chip>
                    </span>

                    <span v-if="item.status == 'pending'">
                      <v-btn
                        class="btn-create lfont btn ms-2 mr-2"
                        color="error"
                        small
                        @click="modalReject(item.id)"
                      >
                        {{ $t("empLeave.ApproveTable.rejected") }}</v-btn
                      >
                      <v-btn
                        class="btn-create lfont btn"
                        color="success"
                        small
                        @click="modalApprove(item.id)"
                      >
                        {{ $t("empLeave.ApproveTable.approve") }}</v-btn
                      >
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <defaultTableNoResult v-else />
            <Loading v-if="isLoading" />
            <Rejected
              @success="fetchEmployeeLeave"
              @close="dialogR = false"
              :id="id"
              :dialogR="dialogR"
            />
            <Approve
              @success="fetchEmployeeLeave"
              @close="dialog = false"
              :id="id"
              :dialog="dialog"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Rejected from "./approve/Reject.vue";
import Approve from "./approve/Approve.vue";
import Loading from "@/components/Loading";
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";

export default {
  components: {
    Rejected,
    Approve,
    Loading,
    Pagination,
    defaultTableNoResult,
  },
  data() {
    return {
      status: "all",
      dialog: false,
      dialogR: false,
      isLoading: false,
      id: "",
      listEmployeeLeave: [],
      listEmployeeHoliday: [],
      todoStatus: [
        {
          id: 0,
          name: this.$t("empLeave.ApproveStatus.all"),
          value: "all",
        },
        {
          id: 1,
          name: this.$t("empLeave.ApproveStatus.wait_for_approved"),
          value: "pending",
        },
        {
          id: 2,
          name: this.$t("empLeave.ApproveStatus.approved"),
          value: "approved",
        },
        {
          id: 3,
          name: this.$t("empLeave.ApproveStatus.hrApproved"),
          value: "success",
        },
        {
          id: 4,
          name: this.$t("empLeave.ApproveStatus.rejected"),
          value: "rejected",
        },
      ],
    };
  },
  methods: {
    onCreate() {
      this.$router
        .push({
          name: "employee_leave.add",
        })
        .catch(() => {});
    },
    searchFilterItem() {
      this.fetchEmployeeLeave();
      // this.pagination.current_page = 1;
    },
    modalReject(id) {
      this.dialogR = true;
      this.id = id;
    },
    modalApprove(id) {
      this.dialog = true;
      this.id = id;
    },

    fetchEmployeeLeave() {
      this.isLoading = true;
      const items = {
        status: this.status,
        // start_date: "",
        // end_date: "",
        // filter: this.filter,
      };
      this.$axios
        .post(`/line/manager/list/employee/leaves`, items)
        .then((res) => {
          if (res.status === 200) {
            console.log("trr", res);
            this.isLoading = false;
            this.listEmployeeLeave = res.data.data;
            // this.pagination = res.data.data.pagination;
            // if (!this.listEmployeeLeave.length > 0) {
            //   this.pagination.current_page = this.pagination.current_page - 1;
            // }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchEmployeeLeave();
    // this.fetchEmployeeHoliday();
  },
};
</script>

<style lang="scss" scoped>
.custom_table {
  margin-top: 10px;
  border: 1px solid rgb(131, 128, 128);
  width: auto;
  height: auto;
  padding: 5px;
}
</style>
